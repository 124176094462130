export default [
    // 参考路由
    // {
    //     path: '/recycleFiles',  // 路径
    //     name: 'recycleFiles', // 名称
    //     component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/recycleFiles"),  // 引用位置
    //     meta: {
    //         realRouter: '/recycleBin' // 菜单激活判断
    //     }
    // },

    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/singleSignOn")
    },
    // 错误页面
    {
        path: '/errorPage',
        name: 'errorPage',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/errorPage")
    },
    // 错误页面（移动端）
    {
        path: '/errorPageMove',
        name: 'errorPageMove',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/errorPageMove")
    },
    // 日报(pc)
    {
        path: '/dailyPc',
        name: 'dailyPc',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/dailyPc")
    },
    // 日报(m)
    {
        path: '/dailyM',
        name: 'dailyM',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/dailyM")
    },
    // // 无权限页面
    // {
    //     path: '/noAccess',
    //     name: 'noAccess',
    //     component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/noAccess")
    // },
    {
        path: '/',
        name: 'home',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/home"),
        children:[
            // 统计
            {
                path: '/statistics',
                name: 'statistics',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/statistics")
            },
            // 统计(管理)
            {
                path: '/statisticsAdmin',
                name: 'statisticsAdmin',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/statisticsAdmin")
            },
            // 稿件
            {
                path: '/manuscript',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/mainManuscript"),
                children: [
                    // 稿件
                    {
                        path: '',
                        name: 'manuscript',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/manuscript"),
                        meta: {
                            realRouter: '/manuscript', // 菜单激活判断
                            keepalive: true
                        }
                    },
                    // 稿件添加
                    {
                        path: '/addManuscripts',
                        name: 'addManuscripts',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/addManuscripts"),
                        meta: {
                            realRouter: '/manuscript' // 菜单激活判断
                        }
                    },
                    // 稿件编辑 （编辑）
                    {
                        path: '/editingManuscripts',
                        name: 'editingManuscripts',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/editingManuscripts"),
                        meta: {
                            realRouter: '/manuscript' // 菜单激活判断
                        }
                    },
                    // 稿件查看 （编辑）
                    {
                        path: '/viewManuscript',
                        name: 'viewManuscript',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/viewManuscript"),
                        meta: {
                            realRouter: '/manuscript' // 菜单激活判断
                        }
                    },
                    // 稿件编辑 （审核）
                    {
                        path: '/editingManuscriptsReviewers',
                        name: 'editingManuscriptsReviewers',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/editingManuscriptsReviewers"),
                        props: true,
                        meta: {
                            realRouter: '/manuscript' // 菜单激活判断
                        }
                    },
                ]
            },
            // 人员
            {
                path: '/personnel',
                name: 'personnel',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/personnel")
            },
            // 计划
            {
                path: '/plan',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/mainPlan"),
                children:[
                    // 稿件
                    {
                        path: '',
                        name: 'plan',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/plan"),
                        meta: {
                            realRouter: '/plan', // 菜单激活判断
                        }
                    },
                    // 设置标准
                    {
                        path: '/setStandard',
                        name: 'setStandard',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/setStandard"),
                        meta: {
                            realRouter: '/plan' // 菜单激活判断
                        }
                    },
                    // 设置计划
                    {
                        path: '/setPlan',
                        name: 'setPlan',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/setPlan"),
                        meta: {
                            realRouter: '/plan' // 菜单激活判断
                        }
                    },
                ],
            },


            // 字典
            {
                path: '/dictionary',
                name: 'dictionary',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/dictionary")
            },
            // 设置
            {
                path: '/setting',
                name: 'setting',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/setting")
            },


        ],
    },
]
